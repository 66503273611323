import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'inputInstruction', 'outputInstruction' ]

  connect() {
    this.updateInstruction();
  }

  updateInstruction() {
    this.outputInstructionTarget.textContent = this.inputInstructionTarget.value;
    this.inputInstructionTarget.value === '' ? this.outputInstructionTarget.classList.remove('mb-2') : this.outputInstructionTarget.classList.add('mb-2') ;
  }

}

